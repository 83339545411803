import { Box, Container, Grid, LinearProgress } from '@material-ui/core';
import StudentData from './StudentData';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import { Lesson } from '../../types/Lesson';
import { Pack } from '../../types/Pack';
import { AdditionalData, Student } from '../../types/Student';
import LessonsHistory from './LessonsHistory/LessonsHistory';
import PacksHistory from './PacksHistory/PacksHistory';
import { useContext, useEffect, useState } from 'react';
import AdminContext from '../../contexts/AdminContext/AdminContext';
import CreatePack from './CreatePack/CreatePack';
import useRenderedTimes from '../../hooks/useRenderedTimes';

function StudentComponent(props: Props) {
  const {
    lessons,
    packs,
    handleLessonSelection,
    handlePackSelection,
    openAddNewPackComponent,
    ...studentData
  } = props;
  return (
    <Box marginTop={3}>
      <Container maxWidth='lg'>
        <Box mb={2}>
          <StudentData studentData={studentData} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LessonsHistory
              lessons={lessons}
              handleLessonSelection={handleLessonSelection}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PacksHistory
              packs={packs}
              handlePackSelection={handlePackSelection}
              openAddNewPackComponent={openAddNewPackComponent}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

type Props = {
  lessons: Lesson[];
  handleLessonSelection(lesson: Lesson): void;
  packs: Pack[];
  handlePackSelection(pack: Pack): void;
  student: Student;
  additionalData: AdditionalData;
  openAddNewPackComponent(): void;
};

export default function StudentWrapper() {
  const { status, studentData, lesson, pack } = useContext(AdminContext);
  const { loading, error } = status!;
  const { getStudentData, student, additionalData } = studentData!;
  const [showCreatePackComponent, setShowCreatePackComponent] = useState(false);
  const renderedTimes = useRenderedTimes();

  useEffect(() => {
    setShowCreatePackComponent(false);
  }, [renderedTimes]);

  useEffect(() => {
    getStudentData();
  }, [getStudentData]);

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (loading) return <LinearProgress />;

  const data = {
    ...studentData!,
    student: student!,
    additionalData: additionalData!,
    handleLessonSelection: lesson!.selectLesson,
    handlePackSelection: pack!.selectPack,
    openAddNewPackComponent: () => setShowCreatePackComponent(true),
  };

  if (!additionalData)
    return (
      <ErrorComponent>
        {new Error(`This student haven't signed in yet`)}
      </ErrorComponent>
    );

  return (
    <>
      <CreatePack
        show={showCreatePackComponent}
        discard={() => setShowCreatePackComponent(false)}
      />
      <StudentComponent {...data} />
    </>
  );
}
