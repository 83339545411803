import { Link, useTheme } from '@material-ui/core';
import { ReactComponent as Icon } from '../../../../assets/header/header.svg';
import { Link as RouterLink } from 'react-router-dom';

function Logo() {
  const theme = useTheme();
  return (
    <Link component={RouterLink} to='/dashboard'>
      <Icon height={theme.spacing(5)} color='black' />
    </Link>
  );
}

export default Logo;
