import { useCallback, useMemo, useState } from 'react';
import { GET_STUDENT } from '../../settings/apiEndpoints';
import { AdditionalData, Student } from '../../types/Student';
import { Pack } from '../../types/Pack';
import { Lesson } from '../../types/Lesson';
import { Serialized } from '../../types/Shared';
import { TransactionRunner } from './TransactionRuner';
import { useLocation } from 'react-router-dom';

function useStudentData(transactionRunner: TransactionRunner) {
  const [student, setStudent] = useState<Student>();
  const [additionalData, setAdditionalData] = useState<AdditionalData>();
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [packs, setPacks] = useState<Pack[]>([]);
  const { pathname } = useLocation();

  const getStudentData = useCallback(() => {
    const [, , studentId] = pathname.split('/');
    return transactionRunner({
      info: GET_STUDENT + `/${studentId}`,
      resHandler: async (res) => {
        if (!res.ok) throw new Error(await res.text());
        const data = (await res.json()).data;
        updateStates(unserialize(data));
      },
    });
  }, [transactionRunner, pathname]);

  return useMemo(
    () => ({
      getStudentData,
      student,
      additionalData,
      lessons,
      packs,
    }),
    [getStudentData, student, additionalData, lessons, packs]
  );

  function updateStates(data: Data) {
    const { user, additionalData, appointments, packs } = data;
    setStudent(user);
    setAdditionalData(additionalData);
    setLessons(appointments);
    setPacks(packs);
  }
}

type Data = {
  user: Student;
  additionalData: AdditionalData;
  appointments: Lesson[];
  packs: Pack[];
};

function unserialize(data: Serialized<Data>) {
  const { user, additionalData, appointments, packs } = data;
  return {
    user,
    additionalData: {
      ...additionalData,
      signInHistory: additionalData.signInHistory.map((history) => ({
        ...history,
        date: new Date(history.date),
      })),
    },
    appointments: appointments.map<Lesson>((lesson) => ({
      ...lesson,
      date: new Date(lesson.date),
      endDate: new Date(lesson.endDate),
    })),
    packs: packs.map<Pack>((pack) => ({
      ...pack,
      purchaseDate: new Date(pack.purchaseDate),
      endDate: new Date(pack.endDate),
    })),
  };
}

export default useStudentData;
