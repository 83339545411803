import {
  GET_ACTIVE_PACKS,
  GET_NEXT_LESSONS,
} from '../../settings/apiEndpoints';
import { useCallback, useMemo, useState } from 'react';
import { Lesson } from '../../types/Lesson';
import { Pack } from '../../types/Pack';
import { TransactionRunner } from './TransactionRuner';
import { Serialized } from '../../types/Shared';

function useDashboard(transactionRunner: TransactionRunner) {
  const [activePacks, setActivePacks] = useState<Pack[]>([]);
  const [nextLessons, setNextLessons] = useState<Lesson[]>([]);

  const getDashboard = useCallback(
    () =>
      transactionRunner(
        {
          info: GET_ACTIVE_PACKS,
          resHandler: async (res) => {
            if (!res.ok) throw new Error(await res.text());
            const data = (await res.json()).data;
            const sortedData = unserializePacks(data).sort(
              (a, b) => a.endDate.valueOf() - b.endDate.valueOf()
            );
            setActivePacks(sortedData);
          },
        },
        {
          info: GET_NEXT_LESSONS,
          resHandler: async (res) => {
            if (!res.ok) throw new Error(await res.text());
            const data = (await res.json()).data;
            setNextLessons(userializeLessons(data));
          },
        }
      ),
    [transactionRunner]
  );

  return useMemo(() => ({ activePacks, nextLessons, getDashboard }), [
    activePacks,
    nextLessons,
    getDashboard,
  ]);
}

function unserializePacks(data: Serialized<Pack[]>) {
  return data.map<Pack>((pack) => ({
    ...pack,
    purchaseDate: new Date(pack.purchaseDate),
    endDate: new Date(pack.endDate),
  }));
}

function userializeLessons(data: Serialized<Lesson[]>) {
  return data.map<Lesson>((lesson) => ({
    ...lesson,
    date: new Date(lesson.date),
    endDate: new Date(lesson.endDate),
  }));
}

export default useDashboard;
