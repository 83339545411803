import { isFuture } from 'date-fns/esm';
import { useMemo, useState } from 'react';
import { Pack } from '../../../types/Pack';

function usePacksHistory(packs: Pack[]) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [active, setActive] = useState(true);

  const filteredPacks = useMemo(
    () =>
      packs
        .filter((pack) =>
          active
            ? isFuture(pack.endDate) && pack.classesLeft > 0
            : !isFuture(pack.endDate) || pack.classesLeft <= 0
        )
        .sort((a, b) =>
          active
            ? a.endDate.valueOf() - b.endDate.valueOf()
            : b.endDate.valueOf() - a.endDate.valueOf()
        ),
    [packs, active]
  );

  const from = page * rowsPerPage,
    to = from + rowsPerPage;
  const packsSlice = filteredPacks.slice(from, to);

  return {
    packsSlice,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    filteredPacks,
    active,
    toggle: () => setActive((prev) => !prev),
  };
}

export default usePacksHistory;
