import {
  List,
  ListItem,
  Typography,
  Card,
  Divider,
  CardHeader,
  ListItemText,
} from '@material-ui/core';
import { format, isFuture, formatDistanceToNow } from 'date-fns';
import { Lesson } from '../../types/Lesson';

export function NextLessons(props: Props) {
  const { lessons, handleLessonSelection } = props;

  return (
    <Card>
      <CardHeader title='Next lessons' />
      <List>
        {lessons.map((lesson) => (
          <div key={lesson.id}>
            <Divider />
            <ListItem button onClick={() => handleLessonSelection(lesson)}>
              <ListItemText
                primary={`${
                  isFuture(lesson.date) ? 'Starting' : 'Started'
                } ${formatDistanceToNow(lesson.date, { addSuffix: true })}`}
                secondary={lesson.userName ? lesson.userName : ''}
              />
              <Typography
                align='right'
                color='secondary'
                style={{ fontWeight: 'bold' }}
              >
                {format(lesson.date, `cccc, LLLL do 'at' h:mm aaa`)}
              </Typography>
            </ListItem>
          </div>
        ))}
      </List>
    </Card>
  );
}

type Props = {
  lessons: Lesson[];
  handleLessonSelection(lesson: Lesson): void;
};

export default NextLessons;
