import { useCallback, useState, useMemo } from 'react';
import { Student } from '../../types/Student';
import { GET_STUDENTS } from '../../settings/apiEndpoints';
import { TransactionRunner } from './TransactionRuner';

function useStudents(transactionRunner: TransactionRunner) {
  const [students, setStudents] = useState<Student[]>([]);

  const getStudents = useCallback(async () => {
    transactionRunner({
      info: GET_STUDENTS,
      resHandler: async (res) => {
        if (!res.ok) throw new Error(await res.text());
        const data = (await res.json()).data;
        setStudents(data);
      },
    });
  }, [transactionRunner]);

  return useMemo(() => ({ list: students, getStudents }), [
    students,
    getStudents,
  ]);
}

export default useStudents;
