import { createContext, ReactNode } from 'react';
import useAdmin from './useAdmin';

type ContextType = Partial<ReturnType<typeof useAdmin>>;
const AdminContext = createContext<ContextType>({});

export default AdminContext;

export function AdminProvider({ children }: { children: ReactNode }) {
  const contextValue = useAdmin();
  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
}
