import { Container, Box, Grid, LinearProgress } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import AdminContext from '../../contexts/AdminContext/AdminContext';
import ActivePacks from './ActivePacks';
import NextLessons from './NextLessons';

function Dashboard() {
  const { status, dashboard, lesson, pack } = useContext(AdminContext);
  const { loading, error } = status!;
  const { getDashboard, nextLessons, activePacks } = dashboard!;
  const { selectLesson } = lesson!;
  const { selectPack } = pack!;

  useEffect(() => {
    getDashboard!();
  }, [getDashboard]);

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (loading) return <LinearProgress />;

  return (
    <Container maxWidth='lg'>
      <Box marginY={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <NextLessons
              lessons={nextLessons!}
              handleLessonSelection={selectLesson!}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ActivePacks
              packs={activePacks!}
              handlePackSelection={selectPack!}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Dashboard;
