import {
  PersonOutlineRounded,
  SchoolRounded,
  DashboardRounded,
} from '@material-ui/icons';

const routes = [
  {
    name: 'Dashboard',
    icon: DashboardRounded,
    path: '/dashboard',
  },
  {
    name: 'Students',
    icon: SchoolRounded,
    path: '/students',
  },
  {
    name: 'Account',
    icon: PersonOutlineRounded,
    path: '/account',
  },
];

export default routes;
