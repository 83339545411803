export enum LessonStatus {
  Default,
  CancelledByUser,
  CancelledByAdmin,
}

export type Lesson = {
  id: string;
  userId: string;
  date: Date;
  endDate: Date;
  packId: string;
  status: LessonStatus;
  userName?: string;
};
