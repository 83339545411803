import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItemText,
  withStyles,
  ListItem as ListItemMui,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { Pack } from '../../types/Pack';

const ListItem = withStyles(() => ({
  root: { flexDirection: 'column', alignItems: 'end' },
}))(ListItemMui);

function ActivePacks(props: Props) {
  const { packs, handlePackSelection } = props;

  return (
    <Card>
      <CardHeader title='Currently active packs' />
      <List>
        {packs.map((pack) => (
          <div key={pack.id}>
            <Divider />
            <ListItem button onClick={() => handlePackSelection!(pack)}>
              <ListItemText secondary={pack.userName} />
              <Grid container justify='space-between'>
                <Grid item>
                  <Box>
                    <Typography>{pack.description} Pack</Typography>
                    <Typography>
                      <strong>{pack.classesLeft}</strong> lessons left
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography>
                      Aquire on {pack.purchaseDate.toLocaleDateString()}
                    </Typography>
                    <Typography
                      color='secondary'
                      style={{ fontWeight: 'bold' }}
                    >
                      Expires on {pack.endDate.toLocaleDateString()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
          </div>
        ))}
      </List>
    </Card>
  );
}

type Props = {
  packs: Pack[];
  handlePackSelection(pack: Pack): void;
};

export default ActivePacks;
