import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { ChangeEvent, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminContext from '../../../contexts/AdminContext/AdminContext';

function CreatePack(props: Props) {
  const { name, classesQuantity, daysDuration, handleStateChange } = props;
  return (
    <Box>
      <form noValidate>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          label={`Pack name (dont't include word "Pack")`}
          name='name'
          autoFocus
          value={name}
          onChange={handleStateChange}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='classesQuantity'
          label='Lessons Quantity'
          type='number'
          value={classesQuantity}
          onChange={handleStateChange}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='daysDuration'
          label='Days duration'
          type='number'
          value={daysDuration}
          onChange={handleStateChange}
        />
      </form>
    </Box>
  );
}

type Props = {
  name: string;
  daysDuration: number;
  classesQuantity: number;
  handleStateChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function CreatePackWrapper({ show, discard }: WrapperProps) {
  const [state, setState] = useState({
    name: 'Compensation',
    daysDuration: 7,
    classesQuantity: 1,
  });
  const { studentId: userId } = useParams<{ studentId: string }>();

  function handleStateChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]:
        name === 'daysDuration' || name === 'classesQuantity'
          ? parseInt(value)
          : value,
    });
  }

  const { pack } = useContext(AdminContext);
  const { createPack } = pack!;

  if (!show) return null;

  return (
    <Dialog open>
      <DialogTitle>
        <Typography
          color='primary'
          style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
        >
          Create Pack
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CreatePack
          handleStateChange={handleStateChange}
          name={state.name}
          classesQuantity={state.classesQuantity}
          daysDuration={state.daysDuration}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={discard}>Discard changes</Button>
        <Button
          onClick={() => createPack({ ...state, userId })}
          color='primary'
        >
          Create Pack
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type WrapperProps = {
  show: boolean;
  discard(): void;
};
