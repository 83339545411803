import {
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  Link,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import SessionContext from '../../../contexts/SessionContext';
import defaultRoutes from '../../../routes';

const useStyles = makeStyles((theme) => {
  return {
    contrastColor: {
      color: theme.palette.primary.contrastText,
    },
    bottom: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.secondary.contrastText,
      zIndex: theme.zIndex.appBar,
      '& a': {
        opacity: '70%',
      },
      '& .active': {
        opacity: 'unset',
      },
    },
    top: {
      listStyle: 'none',
      width: 'fit-content',
      color: theme.palette.primary.contrastText,
      '& a': {
        filter: 'brightness(0.8)',
      },
      '& .active': {
        filter: 'unset',
      },
    },
  };
});

function Navbar({ onTop }: Props) {
  const { bottom, top } = useStyles();
  const { user } = useContext(SessionContext);

  const routes = user ? defaultRoutes : [];

  if (onTop)
    return (
      <Grid container component='ul' spacing={2} className={top}>
        {routes.map((route, index) => (
          <Grid key={index} item component='li'>
            <Link component={RouterLink} to={route.path} color='inherit'>
              {route.name}
            </Link>
          </Grid>
        ))}
      </Grid>
    );

  return (
    <Paper className={bottom} square variant='outlined'>
      <BottomNavigation>
        {routes.map((route, index) => (
          <BottomNavigationAction
            component={RouterLink}
            to={route.path}
            key={index}
            label={route.name}
            showLabel
            icon={<route.icon />}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}

type Props = {
  onTop?: boolean;
};

export default Navbar;
