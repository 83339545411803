import {
  Button,
  List,
  ListItem,
  Typography,
  Card,
  Grid,
  Divider,
  FormControlLabel,
  TablePagination,
  Switch,
  Box,
} from '@material-ui/core';
import { Pack } from '../../../types/Pack';
import usePacksHistory from './usePacksHistory';

const PackComponent = (pack: Pack) => (
  <Box minWidth='100%'>
    <Grid container justify='space-between' alignItems='center'>
      <Grid item>
        <Box>
          <Typography>{pack.description} Pack</Typography>
          <Typography>
            Lessons left <strong>{pack.classesLeft}</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography color='textSecondary'>
          Acquired on {pack.purchaseDate.toLocaleDateString()}
        </Typography>
        <Typography color='textSecondary'>
          Expires on <b>{pack.endDate.toLocaleDateString()}</b>
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export function PacksHistory(props: Props) {
  const { packs, handlePackSelection, openAddNewPackComponent } = props;

  const {
    packsSlice,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    filteredPacks,
    active,
    toggle,
  } = usePacksHistory(packs);

  return (
    <Card>
      <Box paddingTop='1rem'>
        <Grid container alignItems='center' justify='space-between'>
          <Grid item>
            <Box ml='16px'>
              <Typography
                variant='subtitle1'
                color='secondary'
                display='inline'
              >
                Packs history
              </Typography>
              <Button color='primary' onClick={openAddNewPackComponent}>
                Add new
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch checked={active} onChange={toggle} size='small' />
              }
              label={active ? 'Active' : 'Expired / Consumed'}
            />
          </Grid>
        </Grid>
      </Box>
      <List>
        {packsSlice.map((pack) => (
          <div key={pack.id}>
            <Divider />
            <ListItem button onClick={() => handlePackSelection(pack)}>
              {PackComponent(pack)}
            </ListItem>
          </div>
        ))}
        <Divider />
      </List>
      <TablePagination
        component='div'
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage='Packs per page'
        rowsPerPage={rowsPerPage}
        count={filteredPacks.length}
        page={page}
        onChangePage={(_, page) => setPage(page)}
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value));
        }}
      />
    </Card>
  );
}

type Props = {
  packs: Pack[];
  handlePackSelection(pack: Pack): void;
  openAddNewPackComponent(): void;
};

export default PacksHistory;
