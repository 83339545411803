import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pack } from '../../types/Pack';
import { TransactionRunner } from './TransactionRuner';
import { useMemo } from 'react';
import { CREATE_PACK } from '../../settings/apiEndpoints';

function usePack(transactionRunner: TransactionRunner) {
  const [pack, setPack] = useState<Pack>();
  const location = useLocation();
  const unselectPack = useCallback(() => setPack(undefined), [setPack]);
  const selectPack = useCallback((pack: Pack) => setPack(pack), [setPack]);

  const createPack = useCallback(
    (body: CreatePackRequestBody) =>
      transactionRunner({
        info: CREATE_PACK,
        init: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        },
        resHandler: async (res) => {
          if (!res.ok) throw new Error(await res.text());
        },
      }),
    [transactionRunner]
  );

  useEffect(() => {
    unselectPack();
  }, [location, unselectPack]);

  return useMemo(
    () => ({
      selected: pack,
      selectPack,
      unselectPack,
      createPack,
    }),
    [pack, selectPack, unselectPack, createPack]
  );
}

export default usePack;

type CreatePackRequestBody = {
  userId: string;
  name: string;
  daysDuration: number;
  classesQuantity: number;
};
