import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends Partial<CustomTheme> {}
}

interface CustomTheme {
  navbar: {
    breakpoint: Breakpoint;
  };
}
export function createCustomTheme(options?: ThemeOptions) {
  return createMuiTheme({
    navbar: { breakpoint: 'sm' },
    ...options,
  });
}

const PRIMARY_COLOR = '#FF725E';
const SECONDARY_COLOR = '#6E4F6E';

const theme = createCustomTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        strong: {
          color: PRIMARY_COLOR,
        },
        ul: {
          listStyleType: 'none',
          textAling: 'center',
        },
      },
    },
    MuiCardHeader: { title: { color: PRIMARY_COLOR, fontWeight: 'bold' } },
  },
  typography: {
    fontFamily: `'Rubik', sans-serif`,
  },
  palette: {
    primary: { main: PRIMARY_COLOR, contrastText: 'white' },
    secondary: { main: SECONDARY_COLOR, contrastText: 'white' },
  },
});

theme.typography.h1 = {
  color: PRIMARY_COLOR,
  fontWeight: 'bold',
  fontSize: '3.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem',
  },
};

theme.typography.h2 = {
  color: PRIMARY_COLOR,
  fontWeight: 'bold',
  fontSize: '3rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem',
  },
};

theme.typography.subtitle1 = {
  color: PRIMARY_COLOR,
  fontWeight: 'bold',
  fontSize: '1.7rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem',
  },
};

export default theme;
