import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useAsync from '../../hooks/useAsync';
import useCustomFetch from '../../hooks/useCustomFetch';
import { TransactionRunner } from './TransactionRuner';
import useDashboard from './useDashboard';
import useLesson from './useLesson';
import usePack from './usePack';
import useStudentData from './useStudentData';
import useStudents from './useStudents';

function useAdmin() {
  const { loading, error, start, end } = useAsync();
  const customFetch = useCustomFetch();
  const location = useLocation();

  const transactionRunner: TransactionRunner = useCallback(
    async (...transactions) => {
      start();
      try {
        const promises = transactions.map(
          async ({ info, init, resHandler }) => {
            const res = await customFetch(info, init);
            if (!res) return;
            if (resHandler) await resHandler(res);
          }
        );
        await Promise.all(promises);
        end();
      } catch (error) {
        end(error);
      }
    },
    [start, customFetch, end]
  );

  const status = useMemo(() => ({ loading, error }), [loading, error]);

  const studentData = useStudentData(transactionRunner);
  const students = useStudents(transactionRunner);
  const lesson = useLesson(transactionRunner);
  const pack = usePack(transactionRunner);
  const dashboard = useDashboard(transactionRunner);

  const cancelLessonWithSideEffects = useCallback(async () => {
    await lesson.cancelLesson();

    if (location.pathname === '/dashboard') await dashboard.getDashboard();
    else if (location.pathname.includes('/students/'))
      await studentData.getStudentData();
  }, [lesson, dashboard, studentData, location]);

  const modifiedLessonState = useMemo(
    () => ({ ...lesson, cancelLesson: cancelLessonWithSideEffects }),
    [lesson, cancelLessonWithSideEffects]
  );

  const createPackWithSideEffects = useCallback(
    async (...params: Parameters<typeof pack.createPack>) => {
      await pack.createPack(params[0]);
      await studentData.getStudentData();
    },
    [pack, studentData]
  );

  const modifiedPackState = useMemo(
    () => ({ ...pack, createPack: createPackWithSideEffects }),
    [pack, createPackWithSideEffects]
  );

  return useMemo(
    () => ({
      status,
      studentData,
      students,
      lesson: modifiedLessonState,
      pack: modifiedPackState,
      dashboard,
    }),
    [
      status,
      studentData,
      students,
      modifiedLessonState,
      modifiedPackState,
      dashboard,
    ]
  );
}

export default useAdmin;
