import {
  Container,
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  makeStyles,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Card,
  CardHeader,
} from '@material-ui/core';
import { useEffect, useContext } from 'react';
import { Student } from '../../types/Student';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import AdminContext from '../../contexts/AdminContext/AdminContext';
import useStudentsList from './useStudentsList';

const useStyles = makeStyles(() => ({
  studentRow: {
    cursor: 'pointer',
  },
}));

function StudentsList({ students }: Props) {
  const { studentRow } = useStyles();

  const {
    filter,
    setFilter,
    studentsSlice,
    rowsPerPage,
    filteredStudents,
    page,
    setPage,
    setRowsPerPage,
    handleStudentSelection,
  } = useStudentsList(students);

  return (
    <Container maxWidth='lg'>
      <Box mt={3}>
        <Card>
          <CardHeader title='Students' />
          <Box>
            <Box margin={1}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='component-outlined'>
                  Filter by id, name or email
                </InputLabel>
                <OutlinedInput
                  id='component-outlined'
                  value={filter}
                  onChange={(event) => setFilter(event.target.value)}
                  label='Filter by id, name or email'
                />
              </FormControl>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell color='secondary'>ID</TableCell>
                  <TableCell color='secondary' align='center'>
                    Name
                  </TableCell>
                  <TableCell color='secondary' align='right'>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsSlice.map((student) => (
                  <TableRow
                    key={student.id}
                    hover
                    className={studentRow}
                    onClick={() => handleStudentSelection(student)}
                  >
                    <TableCell>{student.id}</TableCell>
                    <TableCell align='center'>{student.displayName}</TableCell>
                    <TableCell align='right'>{student.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage='Students per page'
                    rowsPerPage={rowsPerPage}
                    colSpan={3}
                    count={filteredStudents.length}
                    page={page}
                    onChangePage={(_, page) => setPage(page)}
                    onChangeRowsPerPage={(event) =>
                      setRowsPerPage(parseInt(event.target.value))
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </Container>
  );
}

type Props = {
  students: Student[];
};

export default function StudentListWrapper() {
  const { status, students } = useContext(AdminContext);
  const { error, loading } = status!;
  const { getStudents, list } = students!;

  useEffect(() => {
    getStudents!();
  }, [getStudents]);

  if (error) return <ErrorComponent>{error}</ErrorComponent>;
  if (loading) return <LinearProgress />;

  return <StudentsList students={list} />;
}
