import { Container, Box, Button } from '@material-ui/core';
import { auth } from '../../settings/firebase';

function Account() {
  return (
    <Container maxWidth='sm'>
      <Box marginTop={4}>
        <Button
          onClick={() => auth.signOut()}
          fullWidth
          variant='contained'
          color='secondary'
        >
          Sign out
        </Button>
      </Box>
    </Container>
  );
}

export default Account;
