import { isFuture } from 'date-fns/esm';
import { useMemo, useState } from 'react';
import { Lesson } from '../../../types/Lesson';

function useLessonsHistory(lessons: Lesson[]) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [future, setFuture] = useState(true);

  const filteredLessons = useMemo(
    () =>
      lessons
        .filter((lesson) =>
          future ? isFuture(lesson.endDate) : !isFuture(lesson.endDate)
        )
        .sort((a, b) =>
          future
            ? a.date.valueOf() - b.date.valueOf()
            : b.date.valueOf() - a.date.valueOf()
        ),
    [lessons, future]
  );

  const from = page * rowsPerPage,
    to = from + rowsPerPage;
  const lessonsSlice = filteredLessons.slice(from, to);

  return {
    lessonsSlice,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    filteredLessons,
    future,
    toggle: () => setFuture((prev) => !prev),
  };
}

export default useLessonsHistory;
