import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Student } from '../../types/Student';

function useStudentsList(students: Student[]) {
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useHistory();

  const filteredStudents = useMemo(
    () =>
      students.filter((student) => {
        const [emailWithoutDomain] = student.email.split('@');
        const name = student.displayName.toLocaleLowerCase();
        const id = student.id;
        const text = filter.toLocaleLowerCase();
        return (
          emailWithoutDomain.includes(text) ||
          name.includes(text) ||
          id.includes(text)
        );
      }),
    [filter, students]
  );

  useEffect(() => {
    setPage(0);
  }, [filteredStudents]);

  const from = page * rowsPerPage,
    to = from + rowsPerPage;
  const studentsSlice = filteredStudents.slice(from, to);

  function handleStudentSelection(student: Student) {
    history.push(`/students/${student.id}`);
  }

  return {
    filter,
    setFilter,
    studentsSlice,
    rowsPerPage,
    filteredStudents,
    page,
    setPage,
    setRowsPerPage,
    handleStudentSelection,
  };
}

export default useStudentsList;
