import {
  Container,
  Box,
  Grid,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import ErrorComponent from '../shared/ErrorComponent/ErrorComponent';
import LoaderBackdrop from '../shared/BackdropLoader/BackdropLoader';
import useSignIn from './useSignIn';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function SignIn() {
  const { error, loading, signInWithGoogle, signInWithFacebook } = useSignIn();
  const { paper } = useStyles();

  if (error) return <ErrorComponent children={error} />;

  return (
    <>
      <LoaderBackdrop open={loading} />
      <Container component='main' maxWidth='xs'>
        <div className={paper}>
          <Box mb={3}>
            <Typography variant='h1'>Admin Panel</Typography>
          </Box>
          <Box marginBottom={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={signInWithGoogle}
                  fullWidth
                  color='primary'
                  variant='contained'
                  size='large'
                  style={{ backgroundColor: 'indianred' }}
                >
                  <FaGoogle size='30' />
                  <Box marginLeft={1} component='span'>
                    Sign in with Google
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={signInWithFacebook}
                  fullWidth
                  color='secondary'
                  variant='contained'
                  size='large'
                  style={{ backgroundColor: 'dodgerblue' }}
                >
                  <FaFacebook size='30' />
                  <Box marginLeft={1} component='span'>
                    Sign in with Facebook
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </>
  );
}
