import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import StudentComponent from './components/StudentComponent/StudentComponent';
import SessionContext, { SessionProvider } from './contexts/SessionContext';
import theme from './theme';
import SignIn from './components/SignIn/SignIn';
import LoaderBackdrop from './components/shared/BackdropLoader/BackdropLoader';
import { useContext } from 'react';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/shared/Header/Header';
import StudentList from './components/StudentsList/StudentsList';
import Account from './components/Account/Account';
import LessonComponent from './components/LessonComponent/LessonComponent';
import PackComponent from './components/PackComponent/PackComponent';
import { AdminProvider } from './contexts/AdminContext/AdminContext';

function App() {
  const { user } = useContext(SessionContext);

  if (user === undefined) return <LoaderBackdrop open />;
  if (user === null) return <SignIn />;

  return (
    <>
      <LessonComponent />
      <PackComponent />
      <Switch>
        <Route exact path='/students' component={StudentList} />
        <Route path='/students/:studentId' component={StudentComponent} />
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/account' component={Account} />
        <Route render={() => <Redirect to='/dashboard' />} />
      </Switch>
    </>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <SessionProvider>
        <ThemeProvider theme={theme}>
          <AdminProvider>
            <>
              <CssBaseline />
              <Header />
              <App />
            </>
          </AdminProvider>
        </ThemeProvider>
      </SessionProvider>
    </BrowserRouter>
  );
}

export default AppWrapper;
