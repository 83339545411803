import {
  List,
  ListItem,
  Typography,
  Card,
  Grid,
  Divider,
  FormControlLabel,
  TablePagination,
  Switch,
  Box,
} from '@material-ui/core';
import { format, isFuture, formatDistanceToNow } from 'date-fns';
import { Lesson } from '../../../types/Lesson';
import useLessonsHistory from './useLessonsHistory';

const LessonComponent = (lesson: Lesson) => (
  <Box minWidth='100%'>
    <Typography>
      {format(lesson.date, `cccc, LLLL do 'at' h:mm aaa`)}
    </Typography>
    <Typography align='right' color='textSecondary'>
      {isFuture(lesson.date) ? 'Starting ' : 'Started '}
      {formatDistanceToNow(lesson.date, { addSuffix: true })}
    </Typography>
  </Box>
);

export function LessonsHistory(props: Props) {
  const { lessons, handleLessonSelection } = props;

  const {
    lessonsSlice,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    filteredLessons,
    future,
    toggle,
  } = useLessonsHistory(lessons);

  return (
    <Card>
      <Box paddingTop='1rem'>
        <Grid container alignItems='center' justify='space-between'>
          <Grid item>
            <Box ml='16px'>
              <Typography variant='subtitle1'>Lessons history</Typography>
            </Box>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch checked={future} onChange={toggle} size='small' />
              }
              label={future ? 'Future' : 'Past'}
            />
          </Grid>
        </Grid>
      </Box>
      <List>
        {lessonsSlice.map((lesson) => (
          <div key={lesson.id}>
            <Divider />
            <ListItem button onClick={() => handleLessonSelection(lesson)}>
              {LessonComponent(lesson)}
            </ListItem>
          </div>
        ))}
        <Divider />
      </List>
      <TablePagination
        component='div'
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage='Lessons per page'
        rowsPerPage={rowsPerPage}
        count={filteredLessons.length}
        page={page}
        onChangePage={(_, page) => setPage(page)}
        onChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value));
        }}
      />
    </Card>
  );
}

type Props = {
  lessons: Lesson[];
  handleLessonSelection(lesson: Lesson): void;
};

export default LessonsHistory;
