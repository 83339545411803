import { Card, Box, CardContent, Typography } from '@material-ui/core';
import { AdditionalData, Student } from '../../types/Student';

function StudentData({ studentData }: Props) {
  const { student, additionalData } = studentData;
  const { signedUpOn, location } = parseAdditionalData(additionalData);

  return (
    <Card>
      <CardContent>
        <Typography variant='subtitle1' component='h2'>
          {student.displayName}
        </Typography>
        <Typography color='textSecondary' gutterBottom>
          {student.id}
        </Typography>
        <Box marginY={2}>
          <Typography>Email</Typography>
          <Typography gutterBottom color='secondary'>
            <b>{student.email}</b>
          </Typography>
        </Box>
        <Typography variant='caption' gutterBottom>
          Last known location
          <br />
          {location}
        </Typography>
        <Typography color='textSecondary' align='right'>
          Signed up on {signedUpOn.toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
}

type Props = {
  studentData: {
    student: Student;
    additionalData: AdditionalData;
  };
};

function parseAdditionalData(data: AdditionalData) {
  const history = data.signInHistory;
  const signedUpOn = history[0].date;
  const location = history[history.length - 1].location;
  const { country, region, city } = location;
  return { signedUpOn, location: `${city}. ${region}, ${country}` };
}

export default StudentData;
