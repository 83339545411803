import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import { auth } from '../../settings/firebase';
import SessionContext from '../../contexts/SessionContext';
import useAsyncProcess from '../../hooks/useAsync';
import { VALIDATE_USER_PERMISSIONS } from '../../settings/apiEndpoints';

export default function useSignIn() {
  const { loading, error, start, end } = useAsyncProcess();
  const { user, service } = useContext(SessionContext);
  const { subscribe, unsubscribeRef } = service!;

  const history = useHistory();
  const pathFrom =
    useLocation<{ from: Location } | undefined>().state?.from.pathname || '/';

  useEffect(() => {
    if (user) history.replace(pathFrom);
  }, [user, history, pathFrom]);

  return {
    loading,
    error,
    signInWithGoogle,
    signInWithFacebook,
  };

  async function signInWithGoogle() {
    start();
    const provider = new firebase.auth.GoogleAuthProvider();
    unsubscribeRef.current && unsubscribeRef.current();
    try {
      await auth.signInWithPopup(provider);
      await validateUserPermissions();
      end();
      subscribe();
    } catch (error) {
      end(error);
    }
  }

  async function signInWithFacebook() {
    start();
    const provider = new firebase.auth.FacebookAuthProvider();
    unsubscribeRef.current && unsubscribeRef.current();
    try {
      await auth.signInWithPopup(provider);
      await validateUserPermissions();
      end();
      subscribe();
    } catch (error) {
      end(error);
    }
  }

  async function validateUserPermissions() {
    const resp = await fetch(VALIDATE_USER_PERMISSIONS, {
      headers: {
        Authorization: `Bearer ${await auth.currentUser!.getIdToken()}`,
      },
    });

    if (!resp?.ok) {
      await auth.signOut();
      throw new Error(await resp?.text());
    }
  }
}
