import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CANCEL_LESSON } from '../../settings/apiEndpoints';
import { Lesson } from '../../types/Lesson';
import { TransactionRunner } from './TransactionRuner';

function useLesson(transactionRunner: TransactionRunner) {
  const [lesson, setLesson] = useState<Lesson>();
  const location = useLocation();

  const unselectLesson = useCallback(() => setLesson(undefined), [setLesson]);
  const selectLesson = useCallback((lesson: Lesson) => setLesson(lesson), [
    setLesson,
  ]);

  const cancelLesson = useCallback(async () => {
    await transactionRunner({
      info: CANCEL_LESSON + `/${lesson!.id}`,
      init: { method: 'PATCH' },
      resHandler: async (res) => {
        if (!res.ok) throw new Error(await res.text());
      },
    });
    unselectLesson();
  }, [transactionRunner, lesson, unselectLesson]);

  useEffect(() => {
    unselectLesson();
  }, [location, unselectLesson]);

  return useMemo(
    () => ({
      selected: lesson,
      selectLesson,
      unselectLesson,
      cancelLesson,
    }),
    [lesson, selectLesson, unselectLesson, cancelLesson]
  );
}

export default useLesson;
