import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { differenceInMinutes, format } from 'date-fns';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AdminContext from '../../contexts/AdminContext/AdminContext';
import { Lesson, LessonStatus } from '../../types/Lesson';

function LessonComponent({ lesson }: Props) {
  const { id, date, endDate, userName, packId, status, userId } = lesson;

  return (
    <Box>
      <Typography>
        {format(date, `LLLL do 'at' h:mm aaa`)} (
        {differenceInMinutes(endDate, date)} minutes)
      </Typography>
      <Typography color='textSecondary' gutterBottom>
        ID {id}
      </Typography>
      <Typography>Pack from</Typography>
      <Typography color='textSecondary' gutterBottom>
        {packId}
      </Typography>
      <Typography>Student</Typography>
      <Typography
        gutterBottom
        component={RouterLink}
        to={`/students/${userId}`}
      >
        {userId}
        {userName && (
          <>
            <br /> <Typography>{userName}</Typography>
          </>
        )}
      </Typography>
      {status !== LessonStatus.Default && (
        <Typography>
          {status === LessonStatus.CancelledByUser
            ? 'Cancelled by user'
            : 'Cancelled by admin'}
        </Typography>
      )}
    </Box>
  );
}

type Props = { lesson: Lesson };

export default function LessonComponentWrapper() {
  const { lesson } = useContext(AdminContext);
  const { selected, unselectLesson, cancelLesson } = lesson!;

  if (!selected) return null;

  return (
    <Dialog open>
      <DialogTitle>
        <Typography
          color='primary'
          style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
        >
          Lesson details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <LessonComponent lesson={selected} />
      </DialogContent>
      <DialogActions>
        <Button onClick={unselectLesson}>Discard changes</Button>
        <Button onClick={() => cancelLesson!()} color='primary'>
          Cancel this lesson
        </Button>
      </DialogActions>
    </Dialog>
  );
}
