import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AdminContext from '../../contexts/AdminContext/AdminContext';
import { Pack } from '../../types/Pack';

function PackComponent({ pack }: Props) {
  const { id, description, purchaseDate, endDate, userName, userId } = pack;

  return (
    <Box>
      <Typography>{description}</Typography>
      <Typography color='textSecondary' gutterBottom>
        ID {id}
      </Typography>
      <Typography>Acquired on</Typography>
      <Typography color='textSecondary' gutterBottom>
        {format(purchaseDate, `LLLL do 'at' h:mm aaa`)}
      </Typography>
      <Typography>Expires on</Typography>
      <Typography color='textSecondary' gutterBottom>
        {format(endDate, `LLLL do 'at' h:mm aaa`)}
      </Typography>
      <Typography>Student</Typography>
      <Typography
        gutterBottom
        component={RouterLink}
        to={`/students/${userId}`}
      >
        {userId}
        {userName && (
          <>
            <br /> <Typography>{userName}</Typography>
          </>
        )}
      </Typography>
    </Box>
  );
}

type Props = { pack: Pack };

export default function PackComponentWrapper() {
  const { pack } = useContext(AdminContext);
  const { selected, unselectPack } = pack!;

  if (!selected) return null;

  return (
    <Dialog open>
      <DialogTitle>
        <Typography
          color='primary'
          style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
        >
          Pack details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <PackComponent pack={selected} />
      </DialogContent>
      <DialogActions>
        <Button onClick={unselectPack}>Discard changes</Button>
      </DialogActions>
    </Dialog>
  );
}
