// const BASE_URL = 'http://localhost:3001/admin';
const BASE_URL = "https://api.kimysclassroom.com/admin";

export const GET_STUDENTS = BASE_URL + "/users";
export const GET_STUDENT = BASE_URL + "/user";
export const GET_NEXT_LESSONS = BASE_URL + "/appointments/next";
export const GET_ACTIVE_PACKS = BASE_URL + "/packs";
export const CANCEL_LESSON = BASE_URL + "/appointments";
export const CREATE_PACK = BASE_URL + "/packs";
export const VALIDATE_USER_PERMISSIONS = BASE_URL + "/signin";
